<cft-content-section
  [sectionId]="section.id"
  [plot]="section.plot"
  [title]="section.title"
  [secondaryCallToAction]="section.actionButtonText"
  (secondaryCallToActionClick)="mainCallToActionClick.emit()"
  [titleAlignment]="titleAlignment"
  *transloco="let t"
>
  <cft-tabs>
    @if (section.strengthsList.length) {
      <cft-tab
        tabId="how-it-works-section-strengths-tab"
        [text]="t('howItWorksSection.strengths')"
        [active]="activeTab === 'Strengths'"
        [icon]="{collection: 'fas', name: 'dumbbell'}"
      >
        <cft-step-by-step class="px-md pt-xl">
          @for (item of section.strengthsList; track item; let i = $index) {
            <cft-step
              [title]="item.title"
              [description]="item.description"
              [callToActionId]="item.id"
              [callToAction]="item.callToAction"
              [icon]="item.icon"
            ></cft-step>
          }
        </cft-step-by-step>
      </cft-tab>
    }
    @if (section.howItWorksList.length) {
      <cft-tab
        tabId="how-it-works-section-how-it-works-tab"
        [text]="t('howItWorksSection.howItWorks')"
        [active]="activeTab === 'How it works'"
        [icon]="{collection: 'fas', name: 'tasks'}"
      >
        <cft-step-by-step class="px-md pt-xl">
          @for (item of section.howItWorksList; track item; let i = $index) {
            <cft-step
              [title]="item.title"
              [description]="item.description"
              [callToActionId]="item.id"
              [callToAction]="item.callToAction"
              [icon]="item.icon"
              [number]="i + 1"
            ></cft-step>
          }
        </cft-step-by-step>
      </cft-tab>
    }
    @if (section.contactUsList.length) {
      <cft-tab
        tabId="how-it-works-section-contact-us-tab"
        [text]="t('howItWorksSection.contactUs')"
        [active]="activeTab === 'Contact us'"
        [icon]="{collection: 'fas', name: 'phone-alt'}"
      >
        <cft-step-by-step class="px-md pt-xl">
          @for (item of section.contactUsList; track item; let i = $index) {
            <cft-step
              [title]="item.title"
              [description]="item.description"
              [callToActionId]="item.id"
              [callToAction]="item.callToAction"
              callToActionStyle="button-sm"
              [icon]="item.icon"
            ></cft-step>
          }
        </cft-step-by-step>
      </cft-tab>
    }
  </cft-tabs>
</cft-content-section>
