import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core'
import {ActiveTab, HowItWorksSection} from '../../../../core/content/types/how-it-works-section.types'
import {TabsModule} from '../../../../craft/tabs/tabs.modules'
import {StepByStepModule} from '../../../../craft/step-by-step/step-by-step.module'
import {ContentSectionComponent} from '../../../../craft/content-section/content-section.component'
import {TranslocoDirective} from '@ngneat/transloco'

@Component({
  selector: 'bgo-how-it-works-section',
  templateUrl: './how-it-works-section.component.html',
  styles: [':host { display: block }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {ngSkipHydration: 'true'}, //eslint-disable-line @angular-eslint/no-host-metadata-property
  imports: [ContentSectionComponent, TabsModule, StepByStepModule, TranslocoDirective],
  standalone: true,
})
export class HowItWorksSectionComponent {
  @Input() section!: HowItWorksSection
  @Input() titleAlignment: 'left' | 'center' = 'center'

  @Output() mainCallToActionClick = new EventEmitter<void>()

  get activeTab(): ActiveTab | undefined {
    if (this.section.activeTab === 'Strengths' && this.section.strengthsList.length) {
      return 'Strengths'
    }
    if (this.section.activeTab === 'How it works' && this.section.howItWorksList.length) {
      return 'How it works'
    }
    if (this.section.activeTab === 'Contact us' && this.section.contactUsList.length) {
      return 'Contact us'
    }
    if (this.section.howItWorksList.length) {
      return 'How it works'
    }
    if (this.section.strengthsList.length) {
      return 'Strengths'
    }
    if (this.section.contactUsList.length) {
      return 'Contact us'
    }

    return undefined
  }
}
